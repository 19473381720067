<template>
    <v-container>
        <v-row align="center" justify="space-between" class="mb-4">
            <v-col cols="auto">
            <h1>Lista de tratamientos oportunistas</h1>
            </v-col>
            <v-col cols="auto">
            <v-btn color="primary" @click="crearNuevaTerapiaPop">
                <v-icon left>mdi-plus</v-icon>
                Crear Nuevo tratamiento
            </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="terapiasPop"
            :items-per-page="5"
            class="elevation-1"
        >
        <template #item="{ item }">
            <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.Fecha_inicio }}</td>
                <td>{{ item.Fecha_fin }}</td>
                <td>
                <v-btn small color="primary" @click="actualizarTerapia(item.id)">
                    Editar
                </v-btn>
                <v-btn small color="error" @click="eliminarTerapiaPop(item.id)">
                    Eliminar
                </v-btn>
                </td>
            </tr>
        </template>
        </v-data-table>
    </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
data() {
    return {
    headers: [
        { text: "Identificación Terapia", value: "id" },
        { text: "Fecha Inicio", value: "Fecha_inicio" },
        { text: "Fecha Fin", value: "Fecha_fin" },
        { text: "Acciones", value: "acciones", sortable: false },
    ],
    };
},
computed: {
    ...mapState("terapias", ["terapias", "terapias_pop"]),
    ...mapGetters("terapias", ["getAccionText", "getMotivoText"]),
    terapiasConTexto() {
    return this.terapias.map((terapia) => ({
        ...terapia,
    }));
    },
    terapiasPop() {
    return this.terapias_pop.map((terapia) => ({
        ...terapia,
    }));
    },
},
methods: {
    ...mapActions("terapias", ["actualizarTerapia", "fetchTerapias", "fetchTerapiasPop", "eliminarTerapiaPop"]),
    actualizarTerapia(id) {
    this.$router.push({ name: "detailTAR", params: { idTar: id } });
    },
    crearNuevaTerapiaPop() {
    this.$router.push({ name: "addPOP" });
    },
},
created() {
    this.fetchTerapiasPop();
},
};
</script>