<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="6">
        <v-card>
          <v-card-title>Editar Terapia</v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              v-model="isFormValid"
              @submit.prevent="guardarCambios"
            >
              <v-text-field
                v-model="terapia.fecha_accion"
                label="Fecha Accion"
                type="date"
                :rules="[(v) => !!v || 'Fecha de acción es requerida']"
                required
              ></v-text-field>
              <v-text-field
                v-model="terapia.fecha_fin"
                label="Fecha Fin"
                type="date"
                :rules="[(v) => !!v || 'Fecha de fin es requerida']"
                required
              ></v-text-field>
              <v-select
                v-model="terapia.accion"
                :items="opcionesAccion"
                label="Acción"
                item-text="text"
                item-value="value"
                :rules="[(v) => !!v || 'Acción es requerida']"
                required
              />
              <v-select
                v-model="terapia.motivo"
                :items="opcionesMotivo"
                label="Motivo"
                item-text="text"
                item-value="value"
                :rules="[(v) => !!v || 'Motivo es requerido']"
                required
              />
              <!-- Sección de drogas -->
              <v-card class="mt-4 mb-4" outlined>
                <v-card-title>Drogas</v-card-title>
                <v-card-text>
                  <v-row
                    v-for="(droga, index) in terapia.drogas"
                    :key="droga.id || index + 1"
                    align="center"
                  >
                    <v-col cols="12" sm="3">
                      <v-text-field
                        v-model="droga.nombre"
                        label="Nombre"
                        :rules="[(v) => !!v || 'Nombre es requerido']"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        v-model.number="droga.mg"
                        label="mg"
                        type="number"
                        :rules="[(v) => v > 0 || 'mg debe ser mayor que 0']"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        v-model.number="droga.posologia"
                        label="Posología"
                        type="number"
                        :rules="[
                          (v) => v > 0 || 'Posología debe ser mayor que 0',
                        ]"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-btn color="error" @click="eliminarDroga(index)"
                        >Eliminar</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-btn color="success" @click="agregarDroga"
                    >Agregar Droga</v-btn
                  >
                </v-card-text>
              </v-card>
              <v-btn
                type="submit"
                color="primary"
                class="mr-4"
                :disabled="!isFormValid || isSaving"
              >
                {{ isSaving ? "Guardando..." : "Guardar Terapia" }}
              </v-btn>
              <v-btn @click="cancelar" color="error"> Cancelar </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "EditarTerapia",
  data() {
    return {
      isFormValid: false,
      isSaving: false,
      terapia: {
        id: null,
        identificacion: "",
        fechaInicio: "",
        accion: null,
        motivo: null,
        drogas: [],
      },
    };
  },
  computed: {
    ...mapState("terapias", ["opcionesAccion", "opcionesMotivo"]),
  },
  methods: {
    ...mapActions("terapias", ["actualizarTerapia", "fetchTerapia"]),
    async guardarCambios() {
      if (this.$refs.form.validate()) {
        this.isSaving = true;
        try {
          await this.actualizarTerapia(this.terapia);
          this.$router.push({ name: "tratamientoTAR" });
        } catch (error) {
          console.error("Error al actualizar la terapia:", error);
        } finally {
          this.isSaving = false;
        }
      }
    },
    agregarDroga() {
      this.terapia.drogas.push({
        nombre: "",
        mg: null,
        posologia: null,
      });
    },
    eliminarDroga(index) {
      this.terapia.drogas.splice(index, 1);
    },
    cancelar() {
      this.$router.push({ name: "tratamientoTAR" });
    },
    async cargarTerapia() {
      const id = this.$route.params.idTar;
      try {
        const terapiaCargada = await this.fetchTerapia(id);
        this.terapia = { ...terapiaCargada };
      } catch (error) {
        console.error("Error al cargar la terapia:", error);
        this.$router.push({ name: "tratamientoTAR" });
      }
    },
  },
  created() {
    this.cargarTerapia();
  },
};
</script>
