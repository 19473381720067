<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8">
        <v-card>
          <v-card-title>Agregar Terapia</v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              v-model="isFormValid"
              @submit.prevent="guardarTerapia"
            >
              <v-text-field
                v-model="nuevaTerapia.fecha_accion"
                label="Fecha Accion"
                type="date"
                :rules="[(v) => !!v || 'Fecha de acción es requerida']"
                required
              ></v-text-field>
              <v-text-field
                v-model="nuevaTerapia.fecha_fin"
                label="Fecha Fin"
                type="date"
                :rules="[(v) => !!v || 'Fecha de fin es requerida']"
                required
              ></v-text-field>
              <v-select
                v-model="nuevaTerapia.accion"
                :items="opcionesAccion"
                label="Acción"
                item-text="text"
                item-value="value"
                :rules="[(v) => !!v || 'Acción es requerida']"
                required
              />
              <v-select
                v-model="nuevaTerapia.motivo"
                :items="opcionesMotivo"
                label="Motivo"
                item-text="text"
                item-value="value"
                :rules="[(v) => !!v || 'Motivo es requerido']"
                required
              />
              <v-select
                v-model="nuevaTerapia.art_rs"
                :items="opcionesArt_rs"
                label="Art_rs"
                item-text="text"
                item-value="value"
                :rules="[(v) => !!v || 'art_rs es requerido']"
                required
              />
              <v-select
                v-model="nuevaTerapia.PROTOCOLO"
                :items="protocolo_choices"
                item-text="descripcion"
                item-value="mode"
                label="Protocolo"
                :rules="[(v) => !!v || 'Protocolo es requerido']"
                required
              />
              <v-text-field
                v-model="nuevaTerapia.observacion" 
                label="Observación" 
              ></v-text-field>
              <!-- Sección de drogas -->
              <v-card class="mt-4 mb-4" outlined>
                <v-card-title>Drogas</v-card-title>
                <v-card-text>
                  <v-row
                    v-for="(droga, index) in nuevaTerapia.drogas"
                    :key="index"
                    align="center"
                  >
                    <v-col cols="12" sm="3">
                      <v-select
                        v-model="droga.nombre"
                        :items="drogasss"
                        label="Nombre"
                        return-object
                        single-line
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        v-model.number="droga.mg"
                        label="mg"
                        type="number"
                        :rules="[(v) => v > 0 || 'mg debe ser mayor que 0']"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        v-model.number="droga.posologia"
                        label="Posología"
                        type="number"
                        :rules="[
                          (v) => v > 0 || 'Posología debe ser mayor que 0',
                        ]"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-btn color="error" @click="eliminarDroga(index)"
                        >Eliminar</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-btn color="success" @click="agregarDroga"
                    >Agregar Droga</v-btn
                  >
                </v-card-text>
              </v-card>
              <v-btn
                type="submit"
                color="primary"
                class="mr-4"
                :disabled="!isFormValid || isSaving"
              >
                {{ isSaving ? "Guardando..." : "Guardar Terapia" }}
              </v-btn>
              <v-btn @click="cancelar" color="error"> Cancelar </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "addTAR",
  data() {
    return {
      isFormValid: false,
      isSaving: false,
      nuevaTerapia: {
        fecha_accion: "",
        fecha_fin: "",
        accion: 1 /* null, */,
        motivo: 2 /* null, */,
        art_rs: "1" /* null, */,
        observacion: "",
        PROTOCOLO: 1,
        drogas: [],
      },
    };
  },
  computed: {
    ...mapState("terapias", ["opcionesAccion", "opcionesMotivo", "opcionesArt_rs"]),
    ...mapState("patients", ["patient"]),
    drogasss() {
      return this.$store.getters["datosVariables/drogas"];
    },
    protocolo_choices() {
      return this.$store.getters["datosVariables/protocolo"];
    },
  },
  methods: {
    ...mapActions("terapias", ["crearTerapia"]),
    ...mapActions("patients", ["getPatient"]),
    async guardarTerapia() {
      if (this.$refs.form.validate()) {
        this.isSaving = true;
        try {
          const { rut } = this.patient;
          await this.crearTerapia({
            ...this.nuevaTerapia,
            paciente: rut,
          });
          this.$router.push({ name: "tratamientoTAR" });
        } catch (error) {
          console.error("Error al crear la terapia:", error);
          // Aquí podrías mostrar un mensaje de error al usuario
        } finally {
          this.isSaving = false;
        }
      }
    },
    cancelar() {
      this.$router.push({ name: "tratamientoTAR" });
    },
    agregarDroga() {
      this.nuevaTerapia.drogas.push({
        nombre: "asd" /* "", */,
        mg: 100 /* null, */,
        posologia: 3 / 8 /* null, */,
      });
    },
    eliminarDroga(index) {
      this.nuevaTerapia.drogas.splice(index, 1);
    },
  },
  async created() {
    await this.getPatient(this.$route.params["id"]);
    this.agregarDroga();
  },
};
</script>
