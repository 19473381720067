import actions from './actions.js'
import mutations from './mutations.js'
import getters from './getters.js'

export default {
    namespaced: true,
    state() {
        return {
            patient: null,
            patientEncuesta: null,
            patientVariables: null,
            patientCD4: null,
            patientAuditorias: null,
            patientTAR: null,
            patientPAC: null,
            patientPOP: null,
            pacienteIntervencional: null,
            patientDrop: null,
            patientTerapiaPAC: null,
            patients: [],
            predictions: null,
            tabStatus: { 'variables': true, 'prediccion': true, 'modelo': true },
            statusForm: null,
            new_patient_data: { valor: "hola" }
        }
    },
    actions,
    mutations,
    getters,
}