<template>
  <div>
    <nav-menu></nav-menu>
    <h1 v-if="authStatus === 'authenticating'">{{ authStatus }}</h1>
    <button @click.prevent="oninit" v-if="authStatus === 'authenticating'">
      auth
    </button>
    <router-view class="content-area" v-slot="slotProps" v-else>
      <component :is="slotProps.Component"></component>
    </router-view>
    <AppToast />
    <footer-block></footer-block>
  </div>
</template>

<script>
import FooterBlock from "./components/layouts/FooterBlock.vue";
import AppToast from './components/AppToast.vue';

export default {
  components: { FooterBlock, AppToast },
  data() {
    return {
      authStatus: false,
    };
  },
  methods: {
    async oninit() {
      await this.$store.dispatch("auth/checkAuthentication");
      const x = this.$store.getters["auth/currentState"];
      this.authStatus = x;
    },
  },
  created() {
    this.oninit();
  },
  mounted() {},
  computed: {},
};
</script>

<style>
html {
  font-family: "Roboto", sans-serif;
}
.container-safe-area {
  padding-top: 62px !important;
}
/* button,
button.normal {
  background-color: #504ca3;
  padding: 0.3rem 0.75rem;
  font-family: inherit;
  font-weight: bolder;
  border: 1px solid #504ca3;
  cursor: pointer;
  border-radius: 6px;
  margin: 0.1rem;
  color: rgb(245, 245, 245);
}
button:hover,
button:active {
  background-color: #05006c;
  color: white;
  border-color: #05006c;
}
.flat {
  background-color: transparent;
  color: #3a0061;
  border: none;
} */
</style>
