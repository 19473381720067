var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{staticClass:"container-safe-area"},[_c('section',[_c('v-snackbar',{attrs:{"multi-line":_vm.multiLine,"color":"error"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"black","text":""},on:{"click":function($event){_vm.dialog = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(!_vm.message)?_c('p',[_vm._v(" Faltan respuestas en la encuesta. "),_c('br'),_vm._v(" Por favor llenar las respuestas que faltan ")]):_c('span',[_vm._v(_vm._s(_vm.message))])]),(_vm.loading)?_c('p',[_vm._v("HOLA")]):_vm._e(),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.siguiente.apply(null, arguments)}}},[_c('v-col',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"required":"","rules":[(v) => !!v || 'Falta la respuesta a esta pregunta']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',[_vm._v(" 1. ¿Tienes actualmente alguna de las siguientes condiciones crónicas (diagnosticadas)? ")])]},proxy:true}]),model:{value:(_vm.patientEncuesta.Encuesta_2),callback:function ($$v) {_vm.$set(_vm.patientEncuesta, "Encuesta_2", $$v)},expression:"patientEncuesta.Encuesta_2"}},_vm._l(([
                  'Hipertensión arterial',
                  'Diabetes',
                  'Enfermedad coronaria (por ejemplo: infarto, angina)',
                  'Cancer activo',
                  'Cancer pasado/recuperado',
                  'Enfermedad pulmonar crónica (Por ejemplo: asma, EPOC, fibrosis)',
                  'Hepatitis B',
                  'Hepatitis C',
                  'Enfermedad renal crónica',
                  'Enfermedades psiquiátricas',
                ]),function(n){return _c('v-radio',{key:n,attrs:{"label":`${n}`,"value":n}})}),1)],1)],1),_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"required":"","rules":[(v) => !!v || 'Falta la respuesta a esta pregunta']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',[_vm._v(" 1. Actualmente en relación a tu situación laboral te encuentras (Elige solo una alternativa) ")])]},proxy:true}]),model:{value:(_vm.patientEncuesta.Encuesta_2),callback:function ($$v) {_vm.$set(_vm.patientEncuesta, "Encuesta_2", $$v)},expression:"patientEncuesta.Encuesta_2"}},_vm._l(([
                  'Trabajo con contrato',
                  'Trabajo como independiente con boletas',
                  'Trabajo como independiente sin boletas',
                  'Trabajo no remunerado (ej. Cuidado de familiares, dueña de casa)',
                  'No tengo trabajo/cesante',
                  'Soy jubilado/pensionado',
                  'Soy estudiante',
                  'Soy estudiante y trabajo',
                ]),function(n){return _c('v-radio',{key:n,attrs:{"label":`${n}`,"value":n}})}),1)],1)],1),_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"required":"","rules":[(v) => !!v || 'Falta la respuesta a esta pregunta']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',[_vm._v(" 1. En relación a dónde vives: (Elige solo una alternativa) ")])]},proxy:true}]),model:{value:(_vm.patientEncuesta.Encuesta_2),callback:function ($$v) {_vm.$set(_vm.patientEncuesta, "Encuesta_2", $$v)},expression:"patientEncuesta.Encuesta_2"}},_vm._l(([
                  'Vivienda propia (arrendatario o propietario)',
                  'Vivienda colectiva (Albergue, residencia, pensión, etc.)',
                  'Allegado',
                  'Situación de calle',
                ]),function(n){return _c('v-radio',{key:n,attrs:{"label":`${n}`,"value":n}})}),1)],1)],1),_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"required":"","rules":[(v) => !!v || 'Falta la respuesta a esta pregunta']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',[_vm._v(" 1. Respecto al tratamiento para el VIH ¿Tuviste alguno de estos síntomas?(Marca todos los que correspondan) ")])]},proxy:true}]),model:{value:(_vm.patientEncuesta.Encuesta_2),callback:function ($$v) {_vm.$set(_vm.patientEncuesta, "Encuesta_2", $$v)},expression:"patientEncuesta.Encuesta_2"}},_vm._l(([
                  'Dolor de cabeza',
                  'Mareos',
                  'Náuseas',
                  'Diarrea',
                  'Dolor abdominal',
                  'Somnolencia',
                  'Alergia a la piel',
                  'Ninguno de los anteriores',
                ]),function(n){return _c('v-radio',{key:n,attrs:{"label":`${n}`,"value":n}})}),1)],1)],1),_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"required":"","rules":[(v) => !!v || 'Falta la respuesta a esta pregunta']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',[_vm._v("2. Con respecto a la pregunta anterior, ¿Con qué intensidad? ")])]},proxy:true}]),model:{value:(_vm.patientEncuesta.Encuesta_3),callback:function ($$v) {_vm.$set(_vm.patientEncuesta, "Encuesta_3", $$v)},expression:"patientEncuesta.Encuesta_3"}},_vm._l(([
                  'Leve (no interfiere con tus actividades habituales y no necesita medicación)',
                  'Moderada (se necesita medicación para realizar actividades de la vida diaria)',
                  'Severa (impide el desarrollo de las actividades habituales pese a tomar medicación)',
                ]),function(n){return _c('v-radio',{key:n,attrs:{"required":"","label":`${n}`,"value":n}})}),1)],1)],1),_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"required":"","rules":[(v) => !!v || 'Falta la respuesta a esta pregunta']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',[_vm._v("3. ¿Con qué frecuencia has sentido malestares asociados a tus medicamentos para el VIH? ")])]},proxy:true}]),model:{value:(_vm.patientEncuesta.Encuesta_4),callback:function ($$v) {_vm.$set(_vm.patientEncuesta, "Encuesta_4", $$v)},expression:"patientEncuesta.Encuesta_4"}},_vm._l(([
                  'Nunca',
                  'Raramente (cada 3-6 meses)',
                  'Frecuentemente (al menos una vez al mes)',
                  'Muy frecuentemente (al menos una vez a la semana)',
                  'Constantemente (todos los días)',
                ]),function(n){return _c('v-radio',{key:n,attrs:{"label":`${n}`,"value":n}})}),1)],1)],1),_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"required":"","rules":[(v) => !!v || 'Falta la respuesta a esta pregunta']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',[_vm._v("4. ¿Qué tan a menudo ha sentido cada uno de los siguientes síntomas durante las cuatro últimas semanas? ¿Poco interés o placer para hacer cosas? ")])]},proxy:true}]),model:{value:(_vm.patientEncuesta.Encuesta_5),callback:function ($$v) {_vm.$set(_vm.patientEncuesta, "Encuesta_5", $$v)},expression:"patientEncuesta.Encuesta_5"}},_vm._l(([
                  'Ningún día',
                  'Varios día',
                  'Más de la mitad de los días',
                  'Casi todos los días',
                ]),function(n){return _c('v-radio',{key:n,attrs:{"label":`${n}`,"value":n}})}),1)],1)],1),_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"required":"","rules":[(v) => !!v || 'Falta la respuesta a esta pregunta']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',[_vm._v("5. ¿Qué tan a menudo ha sentido cada uno de los siguientes síntomas durante las cuatro últimas semanas? Se siente deprimido, irritado y sin esperanza ")])]},proxy:true}]),model:{value:(_vm.patientEncuesta.Encuesta_6),callback:function ($$v) {_vm.$set(_vm.patientEncuesta, "Encuesta_6", $$v)},expression:"patientEncuesta.Encuesta_6"}},_vm._l(([
                  'Ningún día',
                  'Varios día',
                  'Más de la mitad de los días',
                  'Casi todos los días',
                ]),function(n){return _c('v-radio',{key:n,attrs:{"label":`${n}`,"value":n}})}),1)],1)],1),_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"required":"","rules":[(v) => !!v || 'Falta la respuesta a esta pregunta']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',[_vm._v("6. Durante las últimas 4 semanas, ¿Con qué frecuencia su salud física o los problemas emocionales han dificultado sus actividades laborales? ")])]},proxy:true}]),model:{value:(_vm.patientEncuesta.Encuesta_7),callback:function ($$v) {_vm.$set(_vm.patientEncuesta, "Encuesta_7", $$v)},expression:"patientEncuesta.Encuesta_7"}},_vm._l(([
                  'Ningún día',
                  'Varios día',
                  'Más de la mitad de los días',
                  'Casi todos los días',
                ]),function(n){return _c('v-radio',{key:n,attrs:{"label":`${n}`,"value":n}})}),1)],1)],1),_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"required":"","rules":[(v) => !!v || 'Falta la respuesta a esta pregunta']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',[_vm._v("7. ¿Alguna vez algún médico o especialista le ha dicho que tiene depresión?")])]},proxy:true}]),model:{value:(_vm.patientEncuesta.Encuesta_8),callback:function ($$v) {_vm.$set(_vm.patientEncuesta, "Encuesta_8", $$v)},expression:"patientEncuesta.Encuesta_8"}},_vm._l(([
                  'Nunca',
                  'Hace un año o menos',
                  'Entre los últimos 2 años',
                  'Hace más de 2 años',
                ]),function(n){return _c('v-radio',{key:n,attrs:{"label":`${n}`,"value":n}})}),1)],1)],1),_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"required":"","rules":[(v) => !!v || 'Falta la respuesta a esta pregunta']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',[_vm._v("8. En tu vida cotidiana, ¿Cuán a menudo sientes que cuentas con alguna persona que te muestre amor y afecto? (ejemplo: esposa/o, compañero/a hijos/as, amigos/as, etc’)")])]},proxy:true}]),model:{value:(_vm.patientEncuesta.Encuesta_9),callback:function ($$v) {_vm.$set(_vm.patientEncuesta, "Encuesta_9", $$v)},expression:"patientEncuesta.Encuesta_9"}},_vm._l(([
                  'Ningún día',
                  'Varios día',
                  'Más de la mitad de los días',
                  'Siempre o casi siempre',
                ]),function(n){return _c('v-radio',{key:n,attrs:{"label":`${n}`,"value":n}})}),1)],1)],1),_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"required":"","rules":[(v) => !!v || 'Falta la respuesta a esta pregunta']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',[_vm._v("9. En el último año, ¿qué tan frecuentemente usaste algún tipo de droga (como por ejemplo: marihuana, popper, coca, éxtasis, keta, G, etc) o alcohol para cambiar la forma en que te sentías? ")])]},proxy:true}]),model:{value:(_vm.patientEncuesta.Encuesta_10),callback:function ($$v) {_vm.$set(_vm.patientEncuesta, "Encuesta_10", $$v)},expression:"patientEncuesta.Encuesta_10"}},_vm._l(([
                  'Nunca',
                  'Menos de una vez al mes',
                  'Más de una vez al mes',
                  'Al menos una vez a la semana',
                  'Al menos una vez al día',
                ]),function(n){return _c('v-radio',{key:n,attrs:{"label":`${n}`,"value":n}})}),1)],1)],1),_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"required":"","rules":[(v) => !!v || 'Falta la respuesta a esta pregunta']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',[_vm._v("10. En el último año, ¿qué tan frecuentemente usaste más drogas o bebiste más alcohol del que habías planeado? ")])]},proxy:true}]),model:{value:(_vm.patientEncuesta.Encuesta_11),callback:function ($$v) {_vm.$set(_vm.patientEncuesta, "Encuesta_11", $$v)},expression:"patientEncuesta.Encuesta_11"}},_vm._l(([
                  'Nunca',
                  'Menos de una vez al mes',
                  'Más de una vez al mes',
                  'Al menos una vez a la semana',
                  'Al menos una vez al día',
                ]),function(n){return _c('v-radio',{key:n,attrs:{"label":`${n}`,"value":n}})}),1)],1)],1),_c('v-row',[_c('v-radio-group',{attrs:{"required":"","rules":[(v) => !!v || 'Falta la respuesta a esta pregunta']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',[_vm._v("11. En el último año, ¿qué tan frecuentemente sentiste que necesitabas o querías dejar de consumir drogas o alcohol y no fuiste capaz?")])]},proxy:true}]),model:{value:(_vm.patientEncuesta.Encuesta_1),callback:function ($$v) {_vm.$set(_vm.patientEncuesta, "Encuesta_1", $$v)},expression:"patientEncuesta.Encuesta_1"}},_vm._l(([
                  'Nunca',
                  'Menos de una vez al mes',
                  'Más de una vez al mes',
                  'Al menos una vez a la semana',
                  'Al menos una vez al día',
                ]),function(n){return _c('v-radio',{key:n,attrs:{"label":`${n}`,"value":n}})}),1)],1),_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"required":"","rules":[(v) => !!v || 'Falta la respuesta a esta pregunta']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',[_vm._v("12. En el último año, ¿Qué tan frecuentemente consumiste drogas o alcohol para ayudarte a sentirte mejor? ")])]},proxy:true}]),model:{value:(_vm.patientEncuesta.Encuesta_11),callback:function ($$v) {_vm.$set(_vm.patientEncuesta, "Encuesta_11", $$v)},expression:"patientEncuesta.Encuesta_11"}},_vm._l(([
                  'Nunca',
                  'Menos de una vez al mes',
                  'Más de una vez al mes',
                  'Al menos una vez a la semana',
                  'Al menos una vez al día',
                ]),function(n){return _c('v-radio',{key:n,attrs:{"label":`${n}`,"value":n}})}),1)],1)],1),_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"required":"","rules":[(v) => !!v || 'Falta la respuesta a esta pregunta']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',[_vm._v("13. ¿Ha tenido problemas entre usted y su pareja, familiares, amigos o laborales a causa del consumo de drogas o alcohol? ")])]},proxy:true}]),model:{value:(_vm.patientEncuesta.Encuesta_11),callback:function ($$v) {_vm.$set(_vm.patientEncuesta, "Encuesta_11", $$v)},expression:"patientEncuesta.Encuesta_11"}},_vm._l(([
                  'Nunca',
                  'Menos de una vez al mes',
                  'Más de una vez al mes',
                  'Al menos una vez a la semana',
                  'Al menos una vez al día',
                ]),function(n){return _c('v-radio',{key:n,attrs:{"label":`${n}`,"value":n}})}),1)],1)],1)],1),_c('v-btn',{attrs:{"type":"submit"}},[_vm._v("Siguiente")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }