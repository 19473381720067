import Router from "vue-router";
import agregarPaciente from "./pages/patients/firstAppointment.vue";
import Home from "./pages/TheHome.vue";
import Pacientes from "./pages/patients/listPatients.vue";
import DetailPaciente from "./components/DetailPaciente.vue";
import Configuracion from "./components/TheConfiguracion.vue";
import Encuesta from "./components/encuestaPaciente.vue";
import EncuestaMonitoreo from "./components/encuestasMonitoreo.vue";
import Variables from "./components/variablesFast.vue";
import Prediction from "./components/predictionFastTrack.vue";
import Intervencional from "./components/modeloIntervencional.vue";
import FastTrack from "./pages/patients/fastTrack.vue";
import subPredictores from "./pages/patients/subPredictores.vue";
import Farmacia from "../src/components/forms/formFarmacia.vue";
import Ecosistema from "../src/components/forms/formEcosistema.vue";
import Depresion from "../src/components/forms/formDepresión.vue";
import Alcohol from "../src/components/forms/formAlcoholYDrogas.vue";
import Extras from "../src/components/forms/formExtras.vue";
import pacienteLaboratorio from "./pages/patients/laboratorioRecords.vue";
import registrosRNA from "./components/forms/registrosRNA.vue";
import registrosCD4 from "./components/forms/registrosCD4.vue";
import pacienteTratamiento from "./pages/patients/pacienteTratamientos.vue";
import tratamientoTAR from "./components/forms/tratamientoTAR.vue";
import tratamientoPOP from "./components/forms/tratamientoPOP.vue";
import entregaRetiros from "./components/forms/entregaRetiros.vue";
import detailTAR from "./components/forms/detailTAR.vue";
import addTAR from "./components/forms/addTAR.vue"
import addPOP from "./components/forms/addPOP.vue"
import addEntrega from "./components/forms/addEntrega.vue"
import Profile from "./pages/TheUserProfile.vue"
import DatosPersonales from "./pages/patients/DatosPersonales.vue";

import store from "@/store";
import Vue from "vue";

Vue.use(Router);
const routes = [
  {
    path: "",
    name: "home",
    component: Home,
    meta: { authorize: [] },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: { authorize: ["Secretario", "Medicos", "Admin"] },
  },
  {
    path: "/agregarPaciente",
    name: "agregarPaciente",
    component: agregarPaciente,
    /* meta: { requiresAuth: true }, */
    meta: { authorize: ["Secretario", "Medicos", "Admin"] },
  },
  {
    path: "/pacientes",
    name: "pacientes",
    component: Pacientes,
    /* meta: { requiresAuth: true }, */
    meta: { authorize: ["Secretario", "Medicos", "Admin"] },
  },
  {
    path: "/configuracion",
    name: "configuracion",
    component: Configuracion,
    meta: { authorize: ["Medicos", "Admin"] },
    /* meta: { requiresAuth: true }, */
  },
  {
    path: "/",
    name: "/",
    meta: { authorize: [] },
    component: () => import(/* webpackChunkName: "Auth Layout" */ "@/modules/auth/layouts/AuthLayout.vue"),
    children: [
      {
        path: "login",
        name: "login",
        meta: { authorize: [] },
        component: () => import(/* webpackChunkName: "Login" */ "@/modules/auth/views/Login_.vue"),
      },
      {
        path: "/register",
        name: "register",
        meta: { authorize: ["Admin"] },
        component: () => import(/* webpackChunkName: "Register" */ "@/modules/auth/views/Register_.vue"),
      },
    ],
  },
  {
    path: "/paciente/:id",
    name: "paciente",
    component: DetailPaciente,
    props: true,
    meta: { authorize: ["Medicos", "Admin"] },
    /*     meta: { requiresAuth: true }, */
    children: [
      {
        path: "/:id/tratamiento",
        name: "pacienteTratamiento",
        component: pacienteTratamiento,
        props: true,
        children: [
          {
            path: "/:id/tratamiento/tratamientoTAR",
            name: "tratamientoTAR",
            component: tratamientoTAR,
            props: true,
          },
          {
            path: "/:id/tratamiento/tratamientoTAR/addTAR",
            name: "addTAR",
            component: addTAR,
            props: true,
          },
          {
            path: "/:id/tratamiento/tratamientoTAR/:idTar",
            name: "detailTAR",
            component: detailTAR,
            props: true,
          },
          {
            path: "/:id/tratamiento/tratamientoPOP",
            name: "tratamientoPOP",
            component: tratamientoPOP,
            props: true,
          },
          {
            path: "/:id/tratamiento/tratamientoPOP/addPOP",
            name: "addPOP",
            component: addPOP,
            props: true,
          },
          {
            path: "/:id/tratamiento/entregaRetiros",
            name: "entregaRetiros",
            component: entregaRetiros,
            props: true,
          },
          {
            path: "/:id/tratamiento/entregaRetiros/addEntrega",
            name: "addEntrega",
            component: addEntrega,
            props: true,
          },
        ],
      },
      {
        path: "/:id/laboratorio",
        name: "pacienteLaboratorio",
        component: pacienteLaboratorio,
        props: true,
        children: [
          {
            path: "/:id/laboratorio/registrosCD4",
            name: "registrosCD4",
            component: registrosCD4,
            props: true,
          },
          {
            path: "/:id/laboratorio/registrosRNA",
            name: "registrosRNA",
            component: registrosRNA,
            props: true,
          },
        ],
      },
      {
        path: "/paciente/:id/fasttrack",
        name: "pacienteFastTrack",
        component: FastTrack,
        meta: { authorize: ["Medicos", "Admin"] },
        props: true,
        children: [
          {
            path: "/paciente/:id/fasttrack/encuesta",
            name: "pacienteFastTrackEncuesta",
            component: Encuesta,
            meta: { authorize: ["Medicos", "Admin"] },
            props: true,
          },
          {
            path: "/paciente/:id/fasttrack/variables",
            name: "pacienteFastTrackVariables",
            component: Variables,
            meta: { authorize: ["Medicos", "Admin"] },
            props: true,
          },
          {
            path: "/paciente/:id/fasttrack/prediction",
            name: "pacienteFastTrackPrediction",
            component: Prediction,
            meta: { authorize: ["Medicos", "Admin"] },
            props: true,
          },
          {
            path: "/paciente/:id/fasttrack/intervencional",
            name: "pacienteFastTrackIntervencional",
            component: Intervencional,
            meta: { authorize: ["Medicos", "Admin"] },
            props: true,
          },
        ],
      },
      {
        path: "/paciente/:id/subPredictores",
        name: "subPredictores",
        component: subPredictores,
        meta: { authorize: ["Medicos", "Admin"] },
        props: true,
        children: [
          {
            path: "/:id/farmacia",
            name: "Farmacia",
            component: Farmacia,
            props: true,
          },
          {
            path: "/:id/ecosistema",
            name: "Ecosistema",
            component: Ecosistema,
            props: true,
          },
          {
            path: "/:id/depresion",
            name: "Depresion",
            component: Depresion,
            props: true,
          },
          {
            path: "/:id/alcohol",
            name: "Alcohol",
            component: Alcohol,
            props: true,
          },
          {
            path: "/:id/extras",
            name: "Extras",
            component: Extras,
            props: true,
          },
          {
            path: "/:id/encuestasMonitoreo",
            name: "EncuestasMonitoreo",
            component: EncuestaMonitoreo,
            props: true,
          },
        ],
      },
      {
        path: "/:id/datos-personales",
        name: "datos-personales",
        component: DatosPersonales,
        meta: { authorize: ["Medicos", "Admin"] },
        props: true,
      },
    ],
  },
];
let router = new Router({
  mode: "history",
  routes: routes,
  scrollBehavior(_, _2, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const { authorize } = to.meta;
  const { ok } = await store.dispatch("auth/checkAuthentication");
  if ((to.name === "home") | (to.path === "/") | (to.name === "ingresoInstituciones")) {
    next();
  } else if (to.name !== "login" && !ok && to.name !== "register") {
    next({ name: "login" });
  } else {
    if (to.name !== "login" /* && to.name !== 'register' */) {
      const tipo = store.getters["auth/autorizacion"];
      if (ok && "Secretario" == tipo && !authorize.includes(tipo)) next({ name: "agregarPaciente" });
      /* if ( ok && 'Medicos'==tipo && !authorize.includes(tipo)) next({name: 'agregarPaciente'}); */ else next();
    } else next();
  }
  /*   if (
    from.name === "pacienteFastTrack" &&
    to.name !== "pacienteFastTrackVariables" &&
    to.name !== "pacienteFastTrackPrediction"
  ) {
    router.app.$store.dispatch("patients/cleanData");
  }
  else if (to.name !== 'login' && !ok){
    
    next({ name: 'login' });
  } 
  else {
    
    if (to.name !== 'login' /* && to.name !== 'register' */
  if (to.name === "pacientes") {
    router.app.$store.dispatch("patients/cleanData");
    await router.app.$store.dispatch("patients/cleanPatient");
  }
  if (to.name === "pacienteFastTrack") {
    await router.app.$store.dispatch("patients/getPatient", router.app.$route.params["id"]);
  }
  if (to.name === "pacienteLaboratorio") {
    next({ name: "registrosCD4" });
  }
  if (to.name === "pacienteFastTrack") {
    next({ name: "pacienteFastTrackEncuesta" });
  }
  if (to.name === "registrosCD4") {
    router.app.$store.commit("patients/setCD4", {});
  }
  if (to.name === "pacientes") {
    router.app.$store.commit("patients/setPredictions", {});
  }
  if (to.name === "subPredictores") {
    next({ name: "Farmacia" });
  }
  if (to.name === "pacienteTratamiento") {
    next({ name: "tratamientoTAR" });
  }
});

export default router;
