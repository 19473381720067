<template>
  <v-container>
      <v-row align="center" justify="space-between" class="mb-4">
          <v-col cols="auto">
          <h1>Lista de tratamientos antiretrovirales</h1>
          </v-col>
          <v-col cols="auto">
          <v-btn color="primary" @click="crearNuevaTerapia">
              <v-icon left>mdi-plus</v-icon>
              Crear Nuevo tratamiento
          </v-btn>
          </v-col>
      </v-row>
      <v-data-table
          :headers="headers"
          :items="terapiasConTexto"
          :items-per-page="5"
          class="elevation-1"
      >
      <template #item="{ item }">
          <tr>
              <td>{{ item.id }}</td>
              <td>{{ item.fecha_accion }}</td>
              <td>{{ item.fecha_fin }}</td>
              <td>{{ item.accionText }}</td>
              <td>{{ item.motivoText }}</td>
              <td>
              <v-btn small color="primary" @click="editarTerapia(item.id)">
                  Editar
              </v-btn>
              <v-btn small color="error" @click="eliminarTerapia(item.id)">
                  Eliminar
              </v-btn>
              </td>
          </tr>
      </template>
      </v-data-table>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
data() {
  return {
  headers: [
      { text: "Identificación Terapia", value: "id" },
      { text: "Fecha Accion", value: "fecha_accion" },
      { text: "Fecha Fin", value: "fecha_fin" },
      { text: "Accion", value: "accionText" },
      { text: "Motivo", value: "motivoText" },
      { text: "Acciones", value: "acciones", sortable: false },
  ],
  };
},
computed: {
  ...mapState("terapias", ["terapias"]),
  ...mapGetters("terapias", ["getAccionText", "getMotivoText"]),
  terapiasConTexto() {
  return this.terapias.map((terapia) => ({
      ...terapia,
      accionText: this.getAccionText(terapia.accion),
      motivoText: this.getMotivoText(terapia.motivo),
  }));
  },
},
methods: {
  ...mapActions("terapias", ["fetchTerapias", "eliminarTerapia"]),
  editarTerapia(id) {
  this.$router.push({ name: "detailTAR", params: { idTar: id } });
  },
  crearNuevaTerapia() {
  this.$router.push({ name: "addTAR" });
  },
},
created() {
  this.fetchTerapias();
},
};
</script>