
import axios from 'axios'
export default {
    namespaced: true,
    state: {
        terapias: [],
        terapias_pop: [],
        entregas: [],
        opcionesAccion: [
            { value: 0, text: "Sin datos" },
            { value: 1, text: "Cambio" },
            { value: 2, text: "Inicio" },
            { value: 3, text: "Continuacion" },
            { value: 4, text: "Reinicio" },
            { value: 5, text: "Reinicio/cambio" },
            { value: 6, text: "Continuacion/cambio" },
        ],
        opcionesMotivo: [
            { value: 0, text: "Simplificacion" },
            { value: 1, text: "Naive" },
            { value: 2, text: "Ram" },
            { value: 3, text: "Stock" },
            { value: 4, text: "Abandono" },
            { value: 5, text: "Traslado" },
            { value: 6, text: "Extranjero" },
            { value: 7, text: "Fracaso virologico" },
            { value: 8, text: "Upgrade" },
            { value: 9, text: "Isapre" },
            { value: 10, text: "Protocolo" },
            { value: 11, text: "Interaccion" },
            { value: 12, text: "Ir" },
            { value: 13, text: "Ajuste dosis" },
            { value: 14, text: "Tbc" },
            { value: 15, text: "Otro" },
            { value: 16, text: "Mala adherencia" },
            { value: 17, text: "Embarazo" },
            { value: 18, text: "Tbc termino" },
            { value: 19, text: "Vhb" },
            { value: 20, text: "Reinicio" },
            { value: 21, text: "Continuacion" },
            { value: 22, text: "Contraindicacion" },
            { value: 23, text: "Fracaso inmunologico" },
            { value: 24, text: "Hospitalizacion" },
            { value: 25, text: "Refuerzo mala adherencia" },
        ],
        opcionesArt_rs: [
            { value: "1", text: "Treatment Failure" },
            { value: "1.1", text: "Virological failure" },
            { value: "1.3", text: "Immunological failure – CD4 drop" },
            { value: "1.4", text: "Clinical Progression" },
            { value: "1.5", text: "Drug resistance based on HIV-1 Genotype" },
            { value: "2", text: "Abnormal fat redistribution" },
            { value: "3", text: "Concern of cardiovascular disease" },
            { value: "3.1", text: "Dyslipidemia" },
            { value: "3.2", text: "Cardiovascular disease" },
            { value: "4", text: "Hypersensitivity reaction (HSR to abacavir)" },
            { value: "5", text: "Toxicity – Predominantly from abdomen/GI tract" },
            { value: "5.1", text: "Toxicity – GI tract" },
            { value: "5.2", text: "Toxicity – Liver" },
            { value: "5.3", text: "Toxicity – Pancreas" },
            { value: "6", text: "Toxicity – Predominantly from nervous system" },
            { value: "6.1", text: "Toxicity – peripheral neuropathy" },
            { value: "6.2", text: "Toxicity – Central Nervous System" },
            { value: "7", text: "Toxicity – Predominantly from kidneys" },
            { value: "8", text: "Toxicity – Predominantly from endocrine system" },
            { value: "8.1", text: "Diabetes" },
            { value: "9", text: "Hematological toxicity (anemia... etc)" },
            { value: "10", text: "Hyperlactataemia/lactic acidosis" },
            { value: "11", text: "Toxicity – Dermatologic" },
            { value: "88", text: "Death" },
            { value: "89", text: "Loss to follow-up" },
            { value: "89.1", text: "Transfer to another center" },
            { value: "90", text: "Side effects – Any of the above, but unspecified" },
            { value: "90.1", text: "Comorbidity" },
            { value: "90.2", text: "Comorbidity resolved" },
            { value: "91", text: "Toxicity, not mentioned above" },
            { value: "92", text: "Availability of more effective treatment" },
            { value: "92.1", text: "Simplified treatment available" },
            { value: "92.3", text: "Drug interaction" },
            { value: "92.31", text: "Drug interaction - commencing TB treatment" },
            { value: "92.32", text: "Drug interaction - ended TB treatment" },
            { value: "92.4", text: "Protocol change" },
            { value: "92.5", text: "Change in eligibility criteria" },
            { value: "92.6", text: "Preferred drug now available" },
            { value: "92.9", text: "Change in treatment not due to side-effects, failure, poor adherence or contra-indication" },
            { value: "93", text: "Structured Treatment Interruption" },
            { value: "93.1", text: "Structured Treatment Interruption (STI) – at high CD4" },
            { value: "94", text: "Patient’s wish/decision, not specified above" },
            { value: "94.1", text: "Non-adherence" },
            { value: "95", text: "Physician’s decision, not specified above" },
            { value: "96", text: "Pregnancy" },
            { value: "96.1", text: "Pregnancy intended or possible" },
            { value: "96.2", text: "Pregnancy ended" },
            { value: "96.3", text: "Preconceptual concerns counseling" },
            { value: "97.6", text: "Drug not available" },
            { value: "98", text: "Other causes, not specified above" },
            { value: "99", text: "Unknown" },
        ]
    },
    mutations: {
        SET_TERAPIAS(state, terapias) {
            state.terapias = terapias
        },
        SET_TERAPIAS_POP(state, terapias_pop) {
            state.terapias_pop = terapias_pop
        },
        SET_ENTREGAS(state, entregas) {
            state.entregas = entregas
        },
        actualizarTerapia(state, terapiaActualizada) {
            const index = state.terapias.findIndex(t => t.id === terapiaActualizada.id);
            if (index !== -1) {
                state.terapias.splice(index, 1, terapiaActualizada);
            }
        },
        agregarTerapia(state, nuevaTerapia) {
            state.terapias.push(nuevaTerapia);
        },
        agregarTerapiaPop(state, nuevaTerapia) {
            state.terapias_pop.push(nuevaTerapia);
        },
        actualizarTerapiasPop(state, id) {
            state.terapias_pop = state.terapias_pop.filter(t => t.id !== id);
        },
        actualizarEntregas(state, id) {
            state.entregas = state.entregas.filter(t => t.uuid !== id);
        },
    },
    getters: {
        getAccionText: (state) => (value) => {
            const accion = state.opcionesAccion.find(option => option.value === value);
            return accion ? accion.text : 'Desconocido';
        },
        getMotivoText: (state) => (value) => {
            const motivo = state.opcionesMotivo.find(option => option.value === value);
            return motivo ? motivo.text : 'Desconocido';
        },
        terapiaIds: (state) => {
            return state.terapias.map(terapia => terapia.id);
        }
    },
    actions: {
        async fetchTerapias({ commit }) {
            try {
                // Asume que tienes una API en /api/terapias
                const response = await axios.get('/terapia_pac/')
                commit('SET_TERAPIAS', response.data)
            } catch (error) {
                console.error('Error fetching terapias:', error)
                // Aquí podrías manejar el error, por ejemplo mostrando una notificación
            }
        },
        async fetchTerapiasPop({ commit }) {
            try {
                // Asume que tienes una API en /api/terapias
                const response = await axios.get('/terapia_pop/')
                commit('SET_TERAPIAS_POP', response.data)
            } catch (error) {
                console.error('Error fetching terapias:', error)
                // Aquí podrías manejar el error, por ejemplo mostrando una notificación
            }
        },
        async fetchEntregas({ commit },id) {
            try {
                // Asume que tienes una API en api/tar/list/
                const response = await axios.get(`api/tar/list/${id}`)
                
                commit('SET_ENTREGAS', response.data.results)
            } catch (error) {
                console.error('Error fetching entregas:', error)
                // Aquí podrías manejar el error, por ejemplo mostrando una notificación
            }
        },
        async fetchTerapia(id) {
            try {
                const response = await axios.get(`/terapia_pac/${id}`);
                return response.data;
            } catch (error) {
                console.error('Error fetching terapia:', error);
                throw error;
            }
        },
        async actualizarTerapia({ commit }, terapia) {
            try {
                const terapiaResponse = await axios.put(`terapia_pac/${terapia.id}/`, terapia)
                const drogasPromises = terapia.drogas.map(droga => {
                    console.log(droga)
                    if (droga.id) {
                        return axios.put(`/droga_pac/${droga.id}/`, {
                            ...droga, terapia_pac: terapia.id
                        })
                    } else {
                        return axios.post("drogas_pac", { ...droga, terapia_pac: terapia.id })
                    }
                })
                await Promise.all(drogasPromises);
                commit('actualizarTerapia', terapiaResponse);
            } catch (error) {
                console.error('Error updating terapia:', error);
                throw error;
            }
        },
        async crearTerapia({ commit }, nuevaTerapia) {
            const { drogas } = nuevaTerapia
            console.log(nuevaTerapia);
            try {
                // Aquí harías la llamada a tu API para crear la nueva terapia
                const terapiaResponse = await axios.post(`terapia_pac/`, nuevaTerapia)
                const terapiaCreada = terapiaResponse.data
                const drogasPromises = drogas.map(droga =>
                    axios.post('droga_pac/', { ...droga, terapia_pac: terapiaCreada.id })
                )
                await Promise.all(drogasPromises)
                // Asumiendo que la API devuelve la nueva terapia creada
                commit('agregarTerapia', terapiaCreada);
            } catch (error) {
                console.error('Error al crear la terapia:', error);
                throw error;
            }
        },
        async crearTerapiaPop({ commit }, nuevaTerapia) {
            const { drogas } = nuevaTerapia
            console.log(nuevaTerapia);
            try {
                // Aquí harías la llamada a tu API para crear la nueva terapia
                const terapiaResponse = await axios.post(`terapia_pop/`, nuevaTerapia)
                const terapiaCreada = terapiaResponse.data
                const drogasPromises = drogas.map(droga =>
                    axios.post('droga_pop/', { ...droga, terapia_pop: terapiaCreada.id })
                )
                await Promise.all(drogasPromises)
                // Asumiendo que la API devuelve la nueva terapia creada
                commit('agregarTerapiaPop', terapiaCreada);
            } catch (error) {
                console.error('Error al crear la terapia:', error);
                throw error;
            }
        },
        /* eslint-disable-next-line no-unused-vars */
        async crearRetiro({commit}, nuevoRetiro) {
            try {
                const response = await axios.post(`api/tar/create`, nuevoRetiro);
                return response.data; 
            } catch (error) {
                console.error('Error al crear el retiro:', error);
                throw error;
            }
        },        
        async eliminarTerapiaPop({ commit }, id) {
            try {
                // Aquí harías la llamada a tu API para crear la nueva terapia
                const terapiaResponse = await axios.delete("terapia_pop/"+id+"/")
                // Asumiendo que la API devuelve la nueva terapia creada
                console.log(terapiaResponse);
                commit('actualizarTerapiasPop', id);
            } catch (error) {
                console.error('Error al eliminar la terapia:', error);
                throw error;
            }
        },
        async eliminarEntrega({ commit }, id) {
            try {
                const response = await axios.delete(`/api/tar/delete/${id}/`);
                if (response.status === 204) {
                    console.log("El recurso fue eliminado correctamente.");
                    commit('actualizarEntregas', id);
                }
            } catch (error) {
                console.error("Error al eliminar el recurso:", error);
                throw error;
            }
        },

    },

}